<template>
  <!--begin::Performance Page-->
  <div class="card-stretch">
    <!--begin::plant changer-->
    <the-plants-selector page="performance" />
    <!--end::plant changer-->

    <div v-if="thePlant" class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5 d-flex justify-content-between">
        <div class="card-title">
          <div class="card-label">
            <div class="font-weight-bolder">{{ thePlant.NAME }}</div>
            <div class="font-size-sm text-primary mt-2">
              {{ thePlant.ADDRESS }}
            </div>
          </div>
        </div>
        <!--begin::dropdown for interval change-->
        <b-dropdown
          size="sm"
          variant="primary"
          toggle-class="custom-v-dropdown btn font-weight-bold py-3 px-6"
          right
          no-flip
          :text="
            interval == 'all-time'
              ? 'All time'
              : interval == 'this-month'
              ? 'Last 30 Days'
              : interval == 'this-week'
              ? 'Last 7 Days'
              : 'Today'
          "
          v-b-tooltip.hover="'Change Interval'"
          style="height: fit-content"
          ref="dropdown"
        >
          <b-dropdown-form style="width: 150px">
            <b-form-radio-group
              label="Select Interval"
              v-model="interval"
              @change="changeInterval"
              :options="intervalRadios"
            ></b-form-radio-group>
          </b-dropdown-form>
          <!-- <b-dropdown-divider></b-dropdown-divider> -->
        </b-dropdown>
        <!--end::dropdown for interval change-->
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body d-flex flex-column">
        <!--begin::Items-->
        <div class="flex-grow-1">
          <!--begin::performance-->
          <div class="font-weight-bolder mb-5 font-size-h4">Performance</div>
          <div
            class="d-flex align-items-center flex-column flex-sm-row justify-content-sm-between mb-10 card-spacer-x"
          >
            <!--begin::widget1-->
            <div class="w-100 h-100 max-w-260px mr-sm-4 mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <!-- <inline-svg
                  src="/media/svg/motive-energy/Equalizer.svg"
                  class="align-self-center"
                  alt=""
                /> -->
                <img
                  class="align-self-center svg__icon"
                  src="/media/svg/motive-energy/Equalizer.svg"
                  alt=""
                />
                <span
                  v-if="performance.totalPV"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h3 text-center"
                  >{{
                    getNumberWithCommas(
                      parseFloat(performance.totalPV).toFixed(0)
                    )
                  }}
                  KWh</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-20px"
                ></span>
                <!--end::spinner used to wait until data load-->
                <span class="text-primary text-center"
                  >Total PV Production</span
                >
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="performance.totalPV_tooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img
                    src="/media/svg/motive-energy/question_mark.svg"
                    alt=""
                  />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget1-->
            <!--begin::widget2-->
            <div class="w-100 max-w-260px mr-sm-4 mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <!-- <inline-svg
                  src="/media/svg/motive-energy/blue-energy.svg"
                  class="align-self-center"
                  alt=""
                /> -->
                <img
                  class="align-self-center svg__icon"
                  src="/media/svg/motive-energy/blue-energy.svg"
                  alt=""
                />

                <span
                  v-if="performance.batteryDischargeEnergy"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h3 text-center"
                  >{{
                    getNumberWithCommas(
                      parseFloat(performance.batteryDischargeEnergy).toFixed(0)
                    )
                  }}
                  KWh</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-30px"
                ></span>
                <!--end::spinner used to wait until data load-->
                <span class="text-primary text-center"
                  >Battery Energy Discharged</span
                >
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="performance.batteryDischargeEnergy_tooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img src="media/svg/motive-energy/question_mark.svg" alt="" />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget2-->
            <!--begin::widget3-->
            <div class="w-100 max-w-260px mr-sm-4 mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <!-- <inline-svg
                  src="media/svg/motive-energy/battery-green.svg"
                  class="h-50 align-self-center"
                  alt=""
                /> -->

                <img
                  class="align-self-center svg__icon"
                  src="/media/svg/motive-energy/battery-green.svg"
                  alt=""
                />

                <span
                  v-if="performance.batteryUtilization"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h3 text-center"
                  >{{
                    parseFloat(performance.batteryUtilization).toFixed(0)
                  }}
                  %</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-20px"
                ></span>
                <!--end::spinner used to wait until data load-->
                <span class="text-primary text-center"
                  >Battery Utilization % (Average)</span
                >
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="performance.batteryUtilization_tooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img src="media/svg/motive-energy/question_mark.svg" alt="" />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget3-->
            <!--begin::widget4-->
            <div class="w-100 max-w-260px mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <inline-svg
                  src="media/svg/motive-energy/Chart_new.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
                <span
                  v-if="performance.totalEnergyOffset"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h3 position-absolute"
                  style="left: 43%; top: 34%"
                  >{{
                    parseFloat(performance.totalEnergyOffset).toFixed(0)
                  }}%</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-20px position-absolute"
                  style="left: 50%; top: 36%"
                ></span>
                <!--begin::spinner used to wait until data load-->
                <span class="text-primary mt-4 text-center"
                  >Total Energy Offset</span
                >
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="performance.totalEnergyOffset_tooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img src="media/svg/motive-energy/question_mark.svg" alt="" />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget4-->
          </div>
          <!--end::performance-->
          <!--begin::benefits-->
          <div class="font-weight-bolder mb-5 font-size-h4">Benefits</div>
          <div
            class="d-flex align-items-center flex-column flex-sm-row justify-content-sm-between mb-10 card-spacer-x"
          >
            <!--begin::widget1-->
            <div class="w-100 max-w-368px mr-sm-4 mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <!-- <inline-svg
                  src="media/svg/motive-energy/money-hand.svg"
                  class="h-50 align-self-center"
                  alt=""
                /> -->

                <img
                  class="align-self-center svg__icon"
                  src="/media/svg/motive-energy/money-hand.svg"
                  alt=""
                />

                <span
                  v-if="benefits.moneySaved"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h2 text-center"
                  >${{
                    getNumberWithCommas(
                      parseFloat(benefits.moneySaved).toFixed(0)
                    )
                  }}</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-20px"
                ></span>
                <!--begin::spinner used to wait until data load-->
                <span class="text-primary text-center">Money Saved</span>
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="benefits.moneySavedTooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img src="media/svg/motive-energy/question_mark.svg" alt="" />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget1-->
            <!--begin::widget2-->
            <div class="w-100 max-w-368px mr-sm-4 mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <!-- <inline-svg
                  src="media/svg/motive-energy/eco-leaf.svg"
                  class="h-50 align-self-center"
                  alt=""
                /> -->

                <img
                  class="align-self-center svg__icon"
                  src="/media/svg/motive-energy/eco-leaf.svg"
                  alt=""
                />
                <span
                  v-if="benefits.trees"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h2 text-center"
                  >{{
                    getNumberWithCommas(parseFloat(benefits.trees).toFixed(0))
                  }}</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-30px"
                ></span>
                <!--begin::spinner used to wait until data load-->
                <span class="text-primary text-center"
                  >Equivalent Number of Trees Planted</span
                >
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="benefits.trees_tooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img src="media/svg/motive-energy/question_mark.svg" alt="" />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget2-->
            <!--begin::widget3-->
            <div class="w-100 max-w-368px mb-4 position-relative">
              <span
                class="d-flex flex-column justify-content-center bg-gray-100 h-180px p-4 rounded-lg"
              >
                <!-- <inline-svg
                  src="media/svg/motive-energy/pollution-vehicles.svg"
                  class="h-50 align-self-center"
                  alt=""
                /> -->
                <img
                  class="align-self-center svg__icon"
                  src="/media/svg/motive-energy/pollution-vehicles.svg"
                  alt=""
                />
                <span
                  v-if="benefits.cars != ''"
                  class="text-dark font-weight-bolder text-hover-primary font-size-h2 text-center"
                  >{{ getNumberWithCommas(parseFloat(benefits.cars)) }}</span
                >
                <!--begin::spinner used to wait until data load-->
                <span
                  v-else
                  class="symbol-label spinner spinner-primary spinner-center h-25px"
                ></span>
                <!--begin::spinner used to wait until data load-->
                <span class="text-primary text-center"
                  >Equivalent Number of Cars Taken off the Road</span
                >
                <!--begin::tooltip icon-->
                <span
                  data-toggle="tooltip"
                  :title="benefits.car_tooltip"
                  class="svg-icon svg-icon-5x tooltip_icon cursor-pointer"
                >
                  <img src="media/svg/motive-energy/question_mark.svg" alt="" />
                </span>
                <!--end::tooltip icon-->
              </span>
            </div>
            <!--end::widget3-->
          </div>
          <!--end::benefits-->
          <!--begin::site configuration-->
          <div class="font-weight-bolder mb-5 font-size-h4">
            Site Configuration
          </div>
          <div>
            <img
              :src="apiURL"
              class="img-fluid"
              style="max-height: 220px"
              alt="site configuration image"
            />
          </div>
        </div>
        <!--end::Items-->
      </div>
      <!--end::Body-->
    </div>
  </div>
  <!--end::Performance Page-->
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ThePlantsSelector from "../../../components/ThePlantsSelector.vue";
import { numberWithCommas } from "@/helpers/Regex";
import store from "@/core/services/store";
import {
  GET_EMS_BY_ID,
  CHANGE_ACTIVE_SITE,
  CHANGE_SITE_NOTIFICATION,
} from "@/store/ems.store";

export default {
  name: "performance",
  components: {
    ThePlantsSelector,
  },
  data() {
    return {
      user: {},
      token: "",
      thePlant: null,
      apiURL: "",
      performance: {
        totalPV: "",
        totalPV_tooltip: "",
        percentBatteryFromPv: "",
        percentBatteryFromPv_tooltip: "",
        batteryDischargeEnergy: "",
        batteryDischargeEnergy_tooltip: "",
        batteryUtilization: "",
        batteryUtilization_tooltip: "",
        totalEnergyOffset: "",
        totalEnergyOffset_tooltip: "",
      },
      benefits: {
        moneySaved: "",
        moneySavedTooltip: "",
        cars: "",
        car_tooltip: "",
        trees: "",
        trees_tooltip: "",
      },
      isCloseable: false,
      startDate: null,
      endDate: null,
      interval: "all-time",
      intervalRadios: [
        { text: "All Time", value: "all-time" },
        { text: "Last 30 Days", value: "this-month" },
        { text: "Last 7 Days", value: "this-week" },
        { text: "Today", value: "today" },
      ],
    };
  },
  async mounted() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      this.token = this.user.signInUserSession.idToken.jwtToken;
    } catch (error) {
      console.log(error);
      this.$router.push({ name: "login" });
      return;
    }
    if (this.getEmsList.length == 0) {
      this.$router.push(`/dashboard`);
    }
    if (this.getEmsList) {
      const plantName = this.$route.params.siteName;

      // console.log(this.getEmsList);
      this.thePlant = this.getEmsList.find((ems) => ems.SITE_ID == plantName);
      // this.getEmsList.forEach(element => {
      //   console.log(element);
      // });
      this.apiURL = `/api/get_site_equipment_image?jwtToken=${this.user.signInUserSession.idToken.jwtToken}&siteId=${plantName}`;

      console.log("Calling getAllWidgetsData", this.thePlant.CONTRACT_START);
      this.getAllWidgetsData(
        this.thePlant.CONTRACT_START,
        new Date(),
        plantName
      ); // Initially load all the widgets data
    }

    store.dispatch(CHANGE_ACTIVE_SITE, this.$route.params.siteName);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: this.thePlant.NAME },
    ]);
  },
  beforeRouteUpdate(to, from, next) {
    // Change page data when switching through the sites
    if (this.getEmsList) {
      const plantName = to.params.siteName;

      this.thePlant = this.getEmsList.find((ems) => ems.SITE_ID == plantName);
      this.clearAllData();
      console.log(
        "Calling getAllWidgetsData beforeRouteUpdate",
        this.thePlant.CONTRACT_START
      );
      this.getAllWidgetsData(
        this.thePlant.CONTRACT_START,
        new Date().toString(),
        plantName
      );
    }

    store.dispatch(CHANGE_ACTIVE_SITE, to.params.siteName);
    const myEms = this.getEmsById(to.params.siteName);
    const ems = this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: myEms ? myEms.NAME : to.params.siteName },
    ]);

    next();
  },
  methods: {
    openReports() {
      this.$router.push(`/reports/${this.thePlant.SITE_ID}`);
    },
    getAllWidgetsData(startDate, endDate, siteId) {
      const intervalStart = new Date(startDate).getTime();
      const intervalEnd = new Date(endDate).getTime();
      ApiService.query("/api/etl/get_total_pv_production", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
          intervalStart: intervalStart,
          intervalEnd: intervalEnd,
        },
      }).then((res) => {
        this.performance.totalPV = res.data.metric;
        this.performance.totalPV_tooltip = res.data.tooltip;
      });

      // ApiService.query("/api/get_battery_energy_from_pv", {
      //   params: {
      //     jwtToken: "this.token,
      //     siteId: siteId,
      //     intervalStart: intervalStart,
      //     intervalEnd: intervalEnd,
      //   },
      // }).then((res) => {
      //   this.performance.percentBatteryFromPv =
      //     res.data.percentBatteryFromPv == "NaN"
      //       ? "0"
      //       : res.data.percentBatteryFromPv;
      // });

      ApiService.query("/api/etl/get_total_energy_offset_etl", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
          intervalStart: intervalStart,
          intervalEnd: intervalEnd,
        },
      }).then((res) => {
        this.performance.totalEnergyOffset =
          res.data.totalEnergyOffset == "NaN"
            ? "0"
            : res.data.totalEnergyOffset;
        this.performance.totalEnergyOffset_tooltip = res.data.tooltip;
      });

      ApiService.query("/api/etl/get_battery_discharge_energy_etl", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
          intervalStart: intervalStart,
          intervalEnd: intervalEnd,
        },
      }).then((res) => {
        this.performance.batteryDischargeEnergy =
          res.data.batteryDischargeEnergy;
        this.performance.batteryDischargeEnergy_tooltip = res.data.tooltip;
      });

      console.log("battery utilization: ", intervalStart, intervalEnd);
      ApiService.query("/api/etl/get_battery_utilization_etl", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
          intervalStart: intervalStart,
          intervalEnd: intervalEnd,
        },
      }).then((res) => {
        console.log("battery utilization: ", res);
        if (res.data.batteryUtilization) {
          this.performance.batteryUtilization = res.data.batteryUtilization;
          this.performance.batteryUtilization_tooltip = res.data.tooltip;
        } else {
          this.performance.batteryUtilization = "0";
          this.performance.batteryUtilization_tooltip = "N/A";
        }
      });

      ApiService.query("/api/etl/get_cars_etl", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
          intervalStart: intervalStart,
          intervalEnd: intervalEnd,
        },
      }).then((res) => {
        this.benefits.cars = res.data.cars;
        this.benefits.car_tooltip = res.data.car_tooltip;
        this.benefits.trees = res.data.trees;
        this.benefits.trees_tooltip = res.data.trees_tooltip;
      });

      ApiService.query("/api/v2/get_money_saved_v2", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
          intervalStart: intervalStart,
          intervalEnd: intervalEnd,
        },
      }).then((res) => {
        this.benefits.moneySaved = res.data.value;
        this.benefits.moneySavedTooltip = res.data.tooltip;
      });

      ApiService.query("/api/get_customer_alerts", {
        params: {
          jwtToken: this.token,
          siteId: siteId,
        },
      }).then((res) => {
        if (res.data.length > 0) {
          store.dispatch(CHANGE_SITE_NOTIFICATION, res.data[0].alertText);
        } else {
          store.dispatch(CHANGE_SITE_NOTIFICATION, "");
        }
      });
    },
    clearAllData() {
      this.performance.totalPV = "";
      this.performance.batteryEnergy = "";
      this.performance.batteryDischargeEnergy = "";
      this.performance.batteryUtilization = "";

      this.benefits.cars = "";
      this.benefits.trees = "";
      this.benefits.moneySaved = "";
    },
    getNumberWithCommas(number) {
      return numberWithCommas(number);
    },
    changeInterval(interval) {
      let startDate;
      const now = new Date();

      if (interval == "all-time") {
        console.log(
          "Calling getAllWidgetsData changeInterval 1",
          this.thePlant.CONTRACT_START,
          now.toString()
        );
        this.getAllWidgetsData(
          this.thePlant.CONTRACT_START,
          now.toString(),
          this.$route.params.siteName
        );
      } else if (interval == "this-month") {
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 30
        );
        console.log(
          "Calling getAllWidgetsData changeInterval 2",
          startDate.toString(),
          now.toString()
        );
        this.getAllWidgetsData(
          startDate,
          now.toString(),
          this.$route.params.siteName
        );
      } else if (interval == "this-week") {
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 7
        );

        this.getAllWidgetsData(
          startDate,
          now.toString(),
          this.$route.params.siteName
        );
      } else if (interval == "today") {
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        this.getAllWidgetsData(
          startDate,
          now.toString(),
          this.$route.params.siteName
        );
      }
    },
  },
  computed: {
    // ...mapGetters(["layoutConfig"])
    ...mapGetters(["getEmsList", "getEmsById"]),
  },
};
</script>

<style scoped>
.tooltip_icon {
  position: absolute;
  right: 5%;
  top: 5%;
}

.svg__icon {
  max-width: 80px;
}
</style>
